define("discourse/plugins/Moderatori Plugin/discourse/initializers/custom-initialize", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "custom-initialize",
    initialize() {
      (0, _pluginApi.withPluginApi)(_pluginApi.PLUGIN_API_VERSION, api => {
        console.log("Inizializzo plugin");
      });
    }
  };
});