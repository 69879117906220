define("discourse/plugins/Moderatori Plugin/discourse/templates/connectors/topic-footer-main-buttons-before-create/button-send", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and this.topic.notification_buttons.[0] this.currentUser.admin) }}
  <CustomButton
    @action={{this.toggleInfo}}
    @label="custom.send_notification"
    @id={{this.topic.id}}
    @topic_id={{this.topic.id}}
    @icon="arrow-up"
    class="btn-default"
  />
  {{/if}}
  
  {{#if this.topic.notification_buttons.[1]}}
  <DButton
    @translatedLabel={{this.topic.notification_buttons.[2]}}
    @action={{fn (mut this.modalIsVisible) true}}
    @icon="eye"
    @class="btn-default"
  />
  {{/if}}
  
  {{#if this.modalIsVisible}}
    <DModal @title="Notifiche inviate" @closeModal={{fn (mut this.modalIsVisible) false}} class="topic-bulk-actions-modal -large">
      <table class="table" style="width: 100%;">
        <tr>
          <th>Utente</th>
          <th>Data</th>
          <th>Tipo</th>
        </tr>
        {{#each this.topic.custom_notifications as |notification|}}
        <tr>
          <td>{{notification.[0]}}</td>
          <td>{{notification.[1]}}</td>
          <td>{{notification.[2]}}</td>
        </tr>
        {{/each}}
      </table>
    </DModal>
  {{/if}}
  */
  {
    "id": "61jDrlKC",
    "block": "[[[41,[28,[37,1],[[30,0,[\"topic\",\"notification_buttons\",\"0\"]],[30,0,[\"currentUser\",\"admin\"]]],null],[[[8,[39,2],[[24,0,\"btn-default\"]],[[\"@action\",\"@label\",\"@id\",\"@topic_id\",\"@icon\"],[[30,0,[\"toggleInfo\"]],\"custom.send_notification\",[30,0,[\"topic\",\"id\"]],[30,0,[\"topic\",\"id\"]],\"arrow-up\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"topic\",\"notification_buttons\",\"1\"]],[[[8,[39,3],null,[[\"@translatedLabel\",\"@action\",\"@icon\",\"@class\"],[[30,0,[\"topic\",\"notification_buttons\",\"2\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"modalIsVisible\"]]],null],true],null],\"eye\",\"btn-default\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"modalIsVisible\"]],[[[1,\"  \"],[8,[39,6],[[24,0,\"topic-bulk-actions-modal -large\"]],[[\"@title\",\"@closeModal\"],[\"Notifiche inviate\",[28,[37,4],[[28,[37,5],[[30,0,[\"modalIsVisible\"]]],null],false],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"table\"],[14,0,\"table\"],[14,5,\"width: 100%;\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Utente\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Data\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Tipo\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[42,[28,[37,11],[[28,[37,11],[[30,0,[\"topic\",\"custom_notifications\"]]],null]],null],null,[[[1,\"      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"td\"],[12],[1,[30,1,[\"0\"]]],[13],[1,\"\\n        \"],[10,\"td\"],[12],[1,[30,1,[\"1\"]]],[13],[1,\"\\n        \"],[10,\"td\"],[12],[1,[30,1,[\"2\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"notification\"],false,[\"if\",\"and\",\"custom-button\",\"d-button\",\"fn\",\"mut\",\"d-modal\",\"table\",\"tr\",\"th\",\"each\",\"-track-array\",\"td\"]]",
    "moduleName": "discourse/plugins/Moderatori Plugin/discourse/templates/connectors/topic-footer-main-buttons-before-create/button-send.hbs",
    "isStrictMode": false
  });
});