import { template as template_b8fedbad57ab4672829ab5ee8c6d4126 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b8fedbad57ab4672829ab5ee8c6d4126(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
