define("discourse/plugins/Moderatori Plugin/discourse/templates/admin/plugins-moderatori", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  <h3>Moderatori</h3>
  <div class="buttons">
    <DButton
      @label="moderatori.show"
      @action={{action "showTentacle"}}
      @icon="eye"
      @id="show-tentacle"
    />
  </div>
  {{#if showText}}
    <h4>Text</h4>
  {{/if}}
  */
  {
    "id": "O0yiLqjn",
    "block": "[[[1,\"\\n\"],[10,\"h3\"],[12],[1,\"Moderatori\"],[13],[1,\"\\n\"],[10,0],[14,0,\"buttons\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@label\",\"@action\",\"@icon\",\"@id\"],[\"moderatori.show\",[28,[37,3],[[30,0],\"showTentacle\"],null],\"eye\",\"show-tentacle\"]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showText\"]],[[[1,\"  \"],[10,\"h4\"],[12],[1,\"Text\"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showText` property path was used in the `discourse/plugins/Moderatori Plugin/discourse/templates/admin/plugins-moderatori.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showText}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"h3\",\"div\",\"d-button\",\"action\",\"if\",\"h4\"]]",
    "moduleName": "discourse/plugins/Moderatori Plugin/discourse/templates/admin/plugins-moderatori.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});