import { template as template_b1d616d03ae94b4aa1f1dc6d6fd3b133 } from "@ember/template-compiler";
const FKLabel = template_b1d616d03ae94b4aa1f1dc6d6fd3b133(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
