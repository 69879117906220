import { template as template_d548cf8974c74348b2ab7c9ba31c92c9 } from "@ember/template-compiler";
const FKText = template_d548cf8974c74348b2ab7c9ba31c92c9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
